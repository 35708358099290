const React = require('react');
const Script = require('nordic/script');


const ScriptPrint = ({ url, nonce }) => (
  <Script>
    {`
        var trackMclicks = document.createElement('img');
        trackMclicks.src = '${url}';
        trackMclicks.nonce = '${nonce}';
        trackMclicks.style.opacity='0.0';
        trackMclicks.style.visibility='hidden';
        trackMclicks.style.position='absolute';
        trackMclicks.style.zIndex='-1';
        trackMclicks.classList.add("ads-carousel-pixel");
        document.body.appendChild(trackMclicks);
    `}
  </Script>
);

module.exports = ScriptPrint;
